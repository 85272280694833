<template>
<div class="tw-w-full tw-flex tw-flex-col">
  <home-navbar/>
   <section class="section-one ">
    <div class="lg:tw-w-6/12 tw-w-full tw-flex tw-flex-col">
      <h6 class="section-one-header">Redefining the future of our kids, with Sports and Sporting Events</h6>
      <h6 class="section-one-desc tw-py-5 tw-hidden md:tw-flex">
        Community and People driven projects for kids and teens engagements through basketball and sports.
      </h6>
    </div>
     <div class="lg:tw-w-6/12 tw-w-full tw-flex tw-flex-col tw-items-end">
      <img class="section-one-img" :src="smiley"  alt="baby girl"/>
       <h6 class="section-one-desc tw-py-5 tw-flex md:tw-hidden">
         Community and People driven projects for kids and teens engagements through basketball and sports.
       </h6>
     </div>
   </section>
  <section class="section-two">
  <div class="lg:tw-w-6/12 tw-w-full " style="background: #FFFFFF">
    <img class="ball" src="https://res.cloudinary.com/basketball4buckland/image/upload/v1654536468/test_folder/saferforkids_jp3fkz.png" alt="ball"/>
  </div>
    <div class="lg:tw-w-5/12 tw-w-full tw-flex tw-flex-col section-two-right ">
      <h6 class="section-one-desc">
        The B4B '22 Summer camp event is a 4 Day sporting event, where we engage our kids in
        basketball activities, drills and routines tailored for them, helping them acquire
        knowledge, skills and discipline required to be successful at the game.

      </h6>

      <v-btn text class="support-btn tw-my-20"  width="250" @click="$router.push({name: 'Register'})">Apply to Camp</v-btn>

<!--      <div class="tw-flex tw-w-full tw-flex-col">-->
<!--        <h6 class="open-quote">" </h6>-->
<!--        <h6 class="quote">If you can just be safer, more informed and efficient while on a journey, then you have got it made.</h6>-->
<!--        <h6 class="open-quote">" </h6>-->
<!--      </div>-->
    </div>
  </section>
  <section class="section-three" >
    <div class="lg:tw-w-6/12 tw-w-full tw-hidden lg:tw-flex">
      <img :src="desktop" alt="desktop" />
    </div>
    <div class="lg:tw-w-5/12 tw-w-full tw-flex tw-flex-col">
<!--      <h6 class="section-three-header">The Great Journey</h6>-->
<!--      <div class="long-line"></div>-->
<!--      <h6 class="section-one-desc tw-py-4">em lorem senectus. Purus fames taciti molestie rhoncus ipsum Ultricies massa cubilia dis montes Integer-->
<!--        magnis tempus per volutpat natoque sodales cubilia dis montes Integer.</h6>-->

      <div class="tw-flex tw-py-8 tw-flex-col">
         <div class="tw-flex tw-w-full tw-flex-row tw-items-center">
            <img :src="book" alt="book" />
           <h6 class="section-three-header-bold tw-ml-5">Our Mission</h6>
         </div>
        <div class="short-line tw-ml-16"></div>

        <h6 class="section-one-desc tw-py-4">Combating social vices through the game of basketball while
          promoting social cohesion, integration, and diversity amongst the young
          participating teenagers yearly.</h6>
      </div>

      <div class="tw-flex tw-py-8 tw-flex-col">
        <div class="tw-flex tw-w-full tw-flex-row tw-items-center">
          <img :src="eye" alt="book" />
          <h6 class="section-three-header-bold tw-ml-5">Our Vision</h6>
        </div>
        <div class="short-line tw-ml-16"></div>

        <h6 class="section-one-desc tw-py-4">Creating a peaceful environment
          promoting self discovery and positive self expression in kids through
          the game of basketball.</h6>
      </div>
    </div>
  </section>
  <section class="section-four">
     <div class="tw-flex tw-w-full lg:tw-w-1/2 tw-flex-col lg:tw-ml-36">
         <h6 class="section-four-semi-bold">The Values that define Us</h6>
       <div class="card tw-mt-20">
         <img :src="circle" alt="circle" class="circle"/>
         <h6 class="card-title tw-mt-20">Card Title</h6>
         <h6 class="card-desc tw-py-6">Sem lorem senectus. Purus fames taciti molestie rhoncus ipsum Ultricies massa cubilia
           dis montes Integer magnis tempus per volutpat natoque sodales.</h6>
       </div>
     </div>

    <div class="tw-flex tw-w-full lg:tw-w-1/2 tw-flex-col ">
      <div class="card">
        <img :src="circle" alt="circle" class="circle"/>
        <h6 class="card-title tw-mt-20">Card Title</h6>
        <h6 class="card-desc tw-py-6">Sem lorem senectus. Purus fames taciti molestie rhoncus ipsum Ultricies massa cubilia
          dis montes Integer magnis tempus per volutpat natoque sodales.</h6>
      </div>
      <div class="card tw-mt-10">
        <img :src="circle" alt="circle" class="circle"/>
        <h6 class="card-title tw-mt-20">Card Title</h6>
        <h6 class="card-desc tw-py-6">Sem lorem senectus. Purus fames taciti molestie rhoncus ipsum Ultricies massa cubilia
          dis montes Integer magnis tempus per volutpat natoque sodales.</h6>
      </div>
    </div>
  </section>
  <section class="section-teams tw-pt-20">
    <h6 class="section-four-semi-bold">Our People</h6>
    <div class="long-line"></div>
    <h6 class="card-desc tw-py-5">Meet our talented team of fun-lovers, exemplary visionaries and philanthropists.</h6>

    <div class="tw-flex tw-w-full tw-mt-20 tw-flex-col lg:tw-flex-row tw-justify-between">

      <div class="tw-flex tw-flex-col tw-w-full lg:tw-w-1/5 lg:tw-items-start tw-items-center tw-mb-10" v-for="(team,index) in teams" :key="index">
        <v-img class="team-img" :src="team.img" />
        <h6 class="team-name tw-px-10 tw-pt-10">{{team.name}}</h6>
        <h6 class="tw-px-10 card-desc">{{team.title}}</h6>
      </div>
    </div>
  </section>
  <Footer/>
</div>
</template>

<script>
import HomeNavbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import smiley from "@/assets/smiley.png"
import ball from "@/assets/ball.png"
import desktop from "@/assets/desktop_set.png"
import book from "@/assets/book.svg"
import  eye from "@/assets/eye.svg"
import circle from "@/assets/circle.svg"
export default {
  name: "AboutView",
  components: { Footer, HomeNavbar },
  data(){
    return{
      smiley,
      ball,
      desktop,
      book,
      eye,
      circle,
      teams:[
        {
          img : "",
          name: "Nimi-Wei Yomi",
          title: "Chairman"
        },
        {
          img : "",
          name: "Chone Chumalusu",
          title: "Secretary"
        },
        {
          img : "",
          name: "Darson Powell",
          title: "Treasurer"
        },
        {
          img : "",
          name: "Luqman Danjuma",
          title: "Vice Chairman"
        },
      ]
    }
  }
};
</script>

<style scoped lang="scss">

.section-one{
  display: flex;
  width: 100%;
  min-height: 100vh;
  background: #FFFBF9;
  align-items: center;

  justify-content: space-between;
  padding: 0 10rem;

  @media screen and (max-width: 1024px){
    padding: 0 2rem;
    flex-direction: column;
    margin-top: 8rem;
  }
}
.section-one-header{
  font-family: 'Roboto',sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 56px;
  text-align: start;
  color: black;

}

.section-one-desc{
  font-family: 'Roboto',sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.1em;
  color: rgba(0, 0, 0, 0.5);
}

.section-one-img{
  width: 680px;
  height: auto;
}

.section-two{
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 10rem;
  background: white;
  @media screen and (max-width: 1024px){
    flex-direction: column;
    padding: 1.5rem;
  }
}

.ball{
  height: 824px;

  @media screen and (max-width: 1024px){
    height: auto;
  }
}

.support-btn{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  text-transform: none;
  background: #F66B05;
}

.open-quote{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.quote{
  font-style: italic;
  font-family: 'Inter',sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #F66B05;
  width: 50%;

  @media screen and (max-width: 1024px){
    width: 100%;
  }
}

.section-two-right{
  display: flex;
  border-bottom: 1px solid rgba(196, 196, 196, 0.7);
  min-height: 100vh;
  justify-content: center;
}

.section-three{
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 10rem;
  background: white;
  @media screen and (max-width: 1024px){
    flex-direction: column;
    padding: 1.5rem;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;


  }
}

.section-four{
  display: none;
  width: 100%;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  padding: 4rem 10rem;
  background: white;

  @media screen and (max-width: 1024px){
    padding: 0 1rem  1rem 2rem;
  }

  @media screen and (max-width: 768px){
    flex-direction: column;
    padding: 1.5rem;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;

  }
}

.section-three-header{
  font-style: normal;
  font-family: 'Inter',sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  color: #F66B05;
}

.section-three-header-bold{
  font-style: normal;
  font-family: 'Inter',sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #F66B05;
}

.section-four-semi-bold{
  font-style: normal;
  font-family: 'Inter',sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #F66B05;
}

.long-line{
  width: 100px;
  height: 1px;
  background: rgba(0, 0, 0, 0.4);
  margin-top: 1rem;
}

.short-line{
  width: 50px;
  height: 1px;
  background: rgba(0, 0, 0, 0.4);
  margin-top: 1rem;
}

.card{
  display: flex;
  width: 450px;
  height: 550px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  padding: 2rem;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;

  @media screen and (max-width: 768px) {
     width: 100%;
     margin-top: 2rem;
  }
}

.circle{
  height: 114px;
}

.card-title{
  font-style: normal;
  font-family: 'Inter',sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  color: #F66B05;
}

.card-desc{
  font-family: 'Roboto',sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 25px;
  letter-spacing: 0.1em;
  color: #000000;
}

.section-teams{
  display: flex;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  flex-direction: column;
  padding: 0 10rem;
  background: white;

  @media screen and (max-width: 1024px){
    padding: 0 1rem  1rem 2rem;
  }

  @media screen and (max-width: 768px) {
    padding:  3rem 1.5rem;
  }
}

.team-img{
  width: 238px;
  height: 331px;
  background: #FFFFFF;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  border-radius: 40%;
}

.team-name{
  font-family: 'Roboto',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
  letter-spacing: 0.1em;
  color: #000000;
}
</style>